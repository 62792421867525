

<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model
            :colon="false"
            :model="form"
            layout="inline"
            @keyup.enter.native="query"
          >
            <a-form-model-item>
              <a-input
                v-model="form.name"
                placeholder="岗位名"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-select
                v-model="form.category"
                placeholder="分类"
                style="width: 150px"
              >
                <a-select-option
                  v-for="item in jobCategoryList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button @click="$router.push($route.path + '/add')" type="primary"
              >添加岗位</a-button
            >
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="岗位名" data-index="name"> </a-table-column>

        <a-table-column title="分类" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="jobCategory" :dictValue="text.category" />
          </template>
        </a-table-column>

        <a-table-column title="类型" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="jobSource" :dictValue="text.source" />
          </template>
        </a-table-column>

        <a-table-column title="学历要求" data-index="degree"> </a-table-column>

        <a-table-column title="经验要求" data-index="experience">
        </a-table-column>

        <a-table-column title="状态" align="center">
          <template slot-scope="text">
            <a-badge v-if="text.status" status="success" text="已上架" />
            <a-badge v-else status="error" text="已下架" />
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center" width="140px">
          <template slot-scope="text">
            <a-space>
              <a href="#" @click.prevent="editText(text)">编辑</a>
              <a href="#" class="danger" @click.prevent="deleteText(text)">
                {{ text.status ? "下架" : "上架" }}
              </a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>


<script>
import watermark from "@/mixins/watermark";
import { fetchList, remove, edit } from "@/api/website/job";
import { mapGetters } from "vuex";
export default {
  mixins: [watermark],

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    jobCategoryList() {
      return this.findDataDict("jobCategory");
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;

      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    editText(text) {
      this.$router.push(this.$route.path + "/edit?id=" + text.id);
    },

    deleteText(text) {
      const that = this;

      if (text.status) {
        this.$confirm({
          title: "确认要下架这个岗位吗？",
          onOk() {
            remove({
              id: text.id,
            }).then(() => {
              that.getList();
            });
          },
        });
      } else {
        this.$confirm({
          title: "确认要上架这个岗位吗？",
          onOk() {
            edit({
              ...text,
              status: 1,
            }).then(() => {
              that.getList();
            });
          },
        });
      }
    },
  },
};
</script>